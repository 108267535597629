import * as Sentry from "@sentry/react";

import { Integrations } from "@sentry/tracing";

import getReactAppKeys from "/imports/client/utils/getReactAppKeys";

import { onError } from "@apollo/client/link/error";

const debug = require("debug")("errorLogging");

// this will work when on production, as the static index file will response the cookie
// but when on development, it will not get when on first load
const clientKeys = getReactAppKeys();

const APP_URL = clientKeys.REACT_APP_URL || window.location.host;
debug("set sentry with public keys %o", clientKeys);
try {
  Sentry.init({
    dsn: clientKeys.REACT_APP_SENTRY,
    integrations: [new Integrations.BrowserTracing()],
    environment: clientKeys.REACT_APP_SERVER_NAME || window.location.host,
    release: clientKeys.REACT_APP_RELEASE_NAME,
    tracesSampleRate: 0.1
  });
  Sentry.setTag("server", APP_URL);
} catch (e) {
  console.warn("sentry not setup correctly:", e.message);
}

onError(({ graphQLErrors, networkError, response }) => {
  if (graphQLErrors) {
    graphQLErrors
      .map(({ message, locations, path }) => Sentry.captureMessage(message, { tags: { ops: "graphql", path }, extra: { path, locations } }));
  }
  if (networkError) {
    Sentry.captureException(networkError, { tags: { ops: "graphql" } });
  }

  // Optionally, set response.errors to null to ignore the captured errors
  // at the component level. Omit this if you still want component-specific handling
  response.errors = null;
});

export { Sentry };
